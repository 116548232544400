
import { API, graphqlOperation, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { listFrontendLeagues } from '@/graphql/queries';
import { getAWSStorageAsset } from '@/services/storage';

/**
 * This function retrieves all the leagues that have been added to the graphql database via NLSE CMS.
 */
export const fetchLeagues = async () => {
    try {
        // Retrieve the leagues from NLSE backend using graphql query.
        const response = await API.graphql({
            ...graphqlOperation(listFrontendLeagues),
            authMode: GRAPHQL_AUTH_MODE.API_KEY
        });

        // Retrieve each of the league items from the response above.
        let leagueItems = response.data.listFrontendLeagues.items;

        // Retreive image for each league item
        leagueItems = await Promise.all(
            leagueItems.map(async (leagueItem) => {
                if(leagueItem.imageFilename != " " || leagueItem.imageFilename.length > 0 ) {

                try {
                    const imageAsset = await getAWSStorageAsset(`League/${leagueItem.imageFilename[0]}`);
                    leagueItem.image = imageAsset;
                    return leagueItem;
                }
                catch(error) {
                    console.log(`Error retrieveing image asset: ${error}`);
                }
            }
            })
        );

        leagueItems.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))

        return leagueItems;
    }
    catch (error) {
        console.log(`Error was found fetching leagues: ${error}`);
    }
};