<template>
	<div class="container">
		<!-- Use responsive class to make table stack on mobile -->
		<b-table
			v-if="isMobile"
			striped
			:items="filteredLeagues"
			:fields="fields"
			stacked
			class="leagues-board"
		>
			<template v-slot:cell(image)="data">
				<div class="image-wrapper">
					<div
						class="image-container"
						:style="{ backgroundImage: `url(${data.value})`, width: '100%' }"
					/>
				</div>
			</template>
			<template v-slot:cell(league_desc)="data">
				<div class="description-wrapper">
					<div v-if="showMore">{{ data.value }}</div>
					<div v-else>{{ data.value.slice(0, 100) }}...</div>
					<button class="show-more-button" @click="showMore = !showMore">
						{{ showMore ? "-Show Less" : " +Show More" }}
					</button>
				</div>
			</template>
		</b-table>

		<b-table
			v-else
			striped
			:items="filteredLeagues"
			:fields="fields"
			class="leagues-board"
		>
			<template v-slot:cell(image)="data">
				<div class="image-wrapper">
					<div
						class="image-container"
						:style="{ backgroundImage: `url(${data.value})`, width: '100%' }"
					/>
				</div>
			</template>
		</b-table>
	</div>
</template>

<script>
import { fetchLeagues } from "@/services/league";
export default {
	name: "Board",
	props: {
		selectedSport: {
			type: String,
			default: "",
		},
		selectedRegion: {
			type: String,
			default: "",
		},
		searchTerm: {
			type: String,
			default: "",
		},
	},

	data() {
		return {
			fields: [
				{
					key: "image",
					label: "",
					thStyle: { width: "20%" },
				},
				{
					key: "Sport",
					sortable: true,
					thStyle: { width: "15%", color: "#EBBA0A", "text-align": "left" },
					tdClass: "leagues-table-data",
				},
				{
					key: "Region",
					thStyle: { width: "15%", color: "#EBBA0A", "text-align": "left" },
					tdClass: "leagues-table-data",
				},
				{
					key: "league_desc",
					label: "League Info",
					thStyle: { width: "60%", color: "#EBBA0A", "text-align": "center" },
					tdClass: "leagues-table-data",
				},
			],
			leagueItems: [],
			filteredItems: [],
			showMore: false,
		};
	},
	watch: {
		selectedSport: function (newVal, oldVal) {
			if (newVal !== oldVal) {
				this.filteredItems = this.filteredLeagues;
				this.$emit("filter-league-items", this.filteredLeagues);
			}
		},
		selectedRegion: function (newVal, oldVal) {
			if (newVal !== oldVal) {
				this.filteredItems = this.filteredLeagues;
				this.$emit("filter-league-items", this.filteredLeagues);
			}
		},
		searchTerm: function (newVal, oldVal) {
			if (newVal !== oldVal) {
				this.filteredItems = this.filteredLeagues;
				this.$emit("filter-league-items", this.filteredLeagues);
			}
		},
	},
	computed: {
		filteredLeagues() {
			if (this.searchTerm !== "") {
				return this.leagueItems.filter(
					(item) =>
						item.Sport.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
						item.Region.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
						item.league_desc
							.toLowerCase()
							.includes(this.searchTerm.toLowerCase())
				);
			}
			if (this.selectedSport === "" && this.selectedRegion === "") {
				return this.leagueItems;
			}
			let filteredItems = this.leagueItems;
			if (this.selectedSport && this.selectedRegion === "") {
				filteredItems = filteredItems.filter(
					(item) => item.Sport === this.selectedSport
				);
			}
			if (this.selectedRegion && this.selectedSport === "") {
				filteredItems = filteredItems.filter(
					(item) => item.Region === this.selectedRegion
				);
			}
			if (this.selectedRegion && this.selectedSport) {
				filteredItems = filteredItems.filter(
					(item) =>
						item.Region === this.selectedRegion &&
						item.Sport === this.selectedSport
				);
			}
			return filteredItems;
		},
		// uniqueRegions() {
		//   const regions = new Set();
		//   this.leagueItems.forEach(item => {
		//     regions.add(item.Region);
		//   });
		//   return Array.from(regions);
		// },

		// This replaces all occurrences of underscores with spaces.
		uniqueRegions() {
			const regions = new Set();
			this.leagueItems.forEach((item) => {
				// Replace underscores with spaces
				const cleanedRegion = item.Region.replace(/_/g, " ");

				regions.add(cleanedRegion);
				// console.log(cleanedRegion);
			});
			return Array.from(regions);
		},

		uniqueSports() {
			const sports = new Set();
			this.leagueItems.forEach((item) => {
				const cleanedsports = item.Sport.replace(/_/g, " ");

				sports.add(cleanedsports);
			});
			return Array.from(sports);
		},
		isMobile() {
			return window.innerWidth <= 768; // Adjust the breakpoint as needed
		},
	},
	async created() {
		const leagues = await fetchLeagues();
		this.leagueItems = leagues.map(
			({ image, region, sport, title, description }) => ({
				title,
				image,
				Region: region.replace(/_/g, " "),
				Sport: sport.replace(/_/g, " "),
				league_desc: description,
			})
		);

		this.$emit("league-items", this.leagueItems);
		this.$emit("unique-regions", this.uniqueRegions);
		this.$emit("unique-sports", this.uniqueSports);
		this.$emit("filter-league-items", this.filteredLeagues);
	},
};
</script>

<style scoped>
.container {
	background-color: #040505;
	display: flex;
	position: relative;
}

.image-container {
	height: 150px;
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
}
.leagues-board {
	color: #bbbbbb;
	text-align: left;
	font-size: 1.3rem;
}

tr:nth-child(odd) {
	background-color: #1d1d1d !important;
	opacity: 1;
}

tr:nth-child(even) {
	background-color: #232323 !important;
	opacity: 1;
}

.table-header {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 150px;
	/* Adjust the height as needed */
}

.header-cell-wrapper {
	display: flex;
	align-items: center;
}

.show-more-button {
	color: #ebba0a;
}

@media (max-width: 768px) {
	.image-wrapper {
		display: flex;
		align-items: center !important;
		justify-content: center !important;
		padding-left: 25%;
		padding-right: -50%;
	}
}
</style>
