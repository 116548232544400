<template>
	<section id="iq-channels" class="s-margin">
		<b-container>
			<b-row>
				<b-col sm="12" class="overflow-hidden">
					<div v-if="leagueList.length > 0">
						<Slick
							class="favorites-slider list-inline row p-0 mb-0"
							ref="dSlick"
							:option="sliderOption"
						>
							<li class="slide-item" v-for="item in leagueList" :key="item.id">
								<!-- <LeagueCard
									:imageUrl="item.image"
									:blurHash="item.blurHash"
									:altText="item.title"
								/> -->
								<div class="position-relative">
									<div class="img-box">
										<!-- @click="showModal(index)" -->
										<img
											:src="item.image"
											@load="imageLoaded = true"
											v-show="imageLoaded"
											class="img-fluid img-border"
											:alt="item.title"
										/>
										<!-- BlurHash canvas displayed initially -->
										<img
											v-if="!imageLoaded"
											:src="decodeBlurHashToImage(item.blurHash)"
											class="img-fluid img-border"
											:alt="item.title"
										/>
									</div>
								</div>
							</li>
						</Slick>
						<svg xmlns="http://www.w3.org/2000/svg" style="display: none">
							<symbol
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 44 44"
								width="44px"
								height="44px"
								id="circle"
								fill="none"
								stroke="currentColor"
							>
								<circle r="20" cy="22" cx="22" id="test"></circle>
							</symbol>
						</svg>
					</div>
					<!-- <div style="text-align: right">
                        <router-link :to="{ name: 'landing-page.channels' }">
                            <h5 style="color: #ffd700">VIEW ALL CHANNELS >>></h5>
                        </router-link>
                    </div> -->
				</b-col>
			</b-row>
		</b-container>
	</section>
</template>
<script>
import { fetchSliderItems } from "@/services/slider.js";
// import LeagueCard from "./LeagueCard.vue";
import { decode } from "blurhash";
import { isBlurhashValid } from "blurhash";

export default {
	name: "LeagueList",
	components: {
		// LeagueCard,
	},
	props: {
		leagueItems: Array,
	},
	mounted() {},
	beforeUpdate() {
		if (this.$refs.dSlick) {
			this.$refs.dSlick.destroy();
		}
	},
	updated() {
		this.$nextTick(function () {
			if (this.$refs.dSlick) {
				this.$refs.dSlick.create(this.sliderOption);
			}
		});
	},
	data() {
		return {
			categoryType: "HighlightedContent",
			leagueList: [],
			imageLoaded: false,
			recommendations: [
				{
					image: require("@/assets/images/frontend/league/CEBL_2.png"),
					title: "Title 1",
					link: "/",
				},
				{
					image: require("@/assets/images/frontend/league/CEBL_2.png"),
					title: "Title 2",
					link: "/",
				},
				{
					image: require("@/assets/images/frontend/league/CEBL_2.png"),
					title: "Title 3",
					link: "/",
				},
				{
					image: require("@/assets/images/frontend/league/CEBL_2.png"),
					title: "Title 4",
					link: "/",
				},
			],
			sliderOption: {
				dots: false,
				arrows: true,
				infinite: true,
				speed: 1800,
				autoplay: true,
				slidesToShow: 3,
				slidesToScroll: 1,
				prevArrow:
					'<div class="slick-prev slick-arrow"><i class="fa fa-chevron-left"></i></div>',
				nextArrow:
					'<div class="slick-next slick-arrow"><i class="fa fa-chevron-right"></i></div>',
				responsive: [
					{
						breakpoint: 1200,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 1,
							infinite: true,
							dots: true,
						},
					},
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1,
						},
					},
					{
						breakpoint: 480,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1,
						},
					},
				],
			},
		};
	},
	methods: {
		createGradientCanvas() {
			const width = 32; // Adjust as needed
			const height = 45; // Adjust as needed
			const canvas = document.createElement("canvas");
			const ctx = canvas.getContext("2d");
			canvas.width = width;
			canvas.height = height;

			// Create gradient
			const gradient = ctx.createLinearGradient(0, 0, width, height);
			gradient.addColorStop(0, "white");
			gradient.addColorStop(1, "black");

			// Fill with gradient
			ctx.fillStyle = gradient;
			ctx.fillRect(0, 0, width, height);

			return canvas.toDataURL();
		},
		decodeBlurHashToImage(blurHashString) {
			const validation = isBlurhashValid(blurHashString);

			if (!validation.result) {
				console.error(
					"Invalid BlurHash string:",
					blurHashString,
					"Error:",
					validation.errorReason
				);
				return this.createGradientCanvas();
			}

			const width = 32;
			const height = 45;

			const pixels = decode(blurHashString, width, height);
			const canvas = document.createElement("canvas");
			const ctx = canvas.getContext("2d");
			canvas.width = width;
			canvas.height = height;

			const imageData = ctx.createImageData(width, height);
			imageData.data.set(pixels);
			ctx.putImageData(imageData, 0, 0);

			return canvas.toDataURL();
		},
		next() {
			this.$refs.dSlick.next();
		},
		prev() {
			this.$refs.dSlick.prev();
		},
		// when you click on an image in the slider, a modal will appear with the title, category, content, and a "Watch Now" button.
		showModal(index) {
			this.$bvModal.show("modal-" + index);
		},
		hideModal(index) {
			this.$bvModal.hide("modal-" + index);
		},
	},
	async mounted() {
		try {
			// Fetch all slider items of a particular category, if specified

			const sliderItems = await fetchSliderItems(this.categoryType);
			this.leagueList = sliderItems;
			if (this.leagueList.length > 0) {
				this.$emit("isLoading", false);
			} else {
				this.$emit("isLoading", true);
			}
		} catch (error) {
			console.error(
				"An error occurred while initializing slider items:",
				error
			);
		}
	},
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap");
.img-box {
    max-width: 355px;
	width: 355px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
	background-color: black;
	border-radius: 30px;
	box-shadow: 0 4px 16px rgb(0 0 0 / 80%);
	/* cursor: pointer; */
	margin: 0 auto;
    overflow: hidden;
	border: 1px solid white;
}
.img-box img {
    max-height: 100%;
    aspect-ratio: 3/4;
    max-width: 100%;
    object-fit: cover;
}

/* adding 4 different media queries here to adjust for sides of image being cut off from screen view at different breakpoints */
@media only screen and (max-width: 1200px) {
	.img-box {
		max-width: 255px;
	}
}

@media only screen and (max-width: 1064px) {
	.img-box {
		max-width: 200px;
	}
}

@media only screen and (max-width: 768px) {
	.img-box {
		max-width: 180px;
	}
}

@media only screen and (max-width: 375px) {
	.img-box {
		max-width: 120px;
	}
}

.img-border {
	border-radius: 30px;
}

.favorites-slider {
	text-align: center !important;
}

/* Modal  */

.modal-description {
	text-align: left;
	font: normal normal normal 18px/32px sans-serif;
	/* font-family: "PT Sans Narrow", sans-serif; */
	letter-spacing: 0px;
	color: #ffffff;
	opacity: 1;
}
/* Watch Now Button */
.btn-watchnow {
	border-radius: 5px !important;
	opacity: 1;
	color: #000 !important;
	background: var(--iq-primary);
	/* background: #ebba0a 0% 0% no-repeat padding-box; */
	border-radius: 5px;
	height: fit-content;
	padding: 3px 12px;
	min-width: fit-content;
}

/* LINK inside the Button */
.btn-watchnow > .custom-link {
	text-align: left;
	/* font: normal normal medium 20px/60px sans-serif; */
	letter-spacing: 0px;
	color: #040505 !important;
	opacity: 1;
	font-family: sans-serif;
	font-size: 18px/20px;
	font-weight: 700;
}
/* Match CSS */
.match-name {
	text-align: left;
	letter-spacing: 0px;
	color: var(--iq-primary);
	opacity: 1;
	font-family: sans-serif;
	font-size: 16px;
	margin-left: 10px;
}

.match-details,
.match-time {
	text-align: left;
	font-family: sans-serif;
	font-size: 14px;
	letter-spacing: 0px;
	color: #ffffff;
	opacity: 1;
	margin-left: 10px;
}
.recommendation {
	text-align: left;
	font-family: sans-serif;
	font-size: 14px;
	letter-spacing: 0px;
	color: #ffffff;
	opacity: 1;
	margin-bottom: 10px;
}

.slick-prev,
.slick-next {
	top: 50% !important;
}
</style>
