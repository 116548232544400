<template>
  <div>
    <div>
      <h2 class="leagues-title">ENDLESS POSSIBILITIES WITH NLSE</h2>
      <LeagueList />
    </div>
    <div class="container">
      <div class="filter-box">
        <div class="filter-section">
          <div class="input-icon">
            <input type="text" v-model="searchTerm" placeholder="Search for a sport or region" class="styled-input" />
          </div>
        </div>
        <div class="filter-section filter-options">
          <b-form-select class="custom-select" v-model="selectedSport"
            :options="[{ text: 'Filter by Sports', value: '' }, ...sportsOptions]" placeholder="Filter by Sport"
            @change="clearSearchTerm">
          </b-form-select>
          <b-form-select class="custom-select" v-model="selectedRegion"
            :options="[{ text: 'Filter by Regions', value: '' }, ...regionsOptions]" placeholder="Filter by Region"
            @change="clearSearchTerm"></b-form-select>
        </div>
      </div>
      <Board @unique-sports="updateUniqueSports" @unique-regions="updateUniqueRegions" :selectedSport="selectedSport"
        :selectedRegion="selectedRegion" :searchTerm="searchTerm" />

    </div>
  </div>
</template>

<script>
import LeagueList from "../Components/NLSE/LeagueList";
import Board from "../../../views/FrontendPages/LeaguesPage/Board.vue";

export default {
  name: 'LeaguesPage',
  components: {
    LeagueList,
    Board,
  },
  data() {
    return {
      items: [],
      uniqueSports: [],
      uniqueRegions: [],
      selectedSport: "",
      selectedRegion: "",
      searchTerm: ""
    };
  },
  computed: {
    sportsOptions() {
      return this.uniqueSports.map(sport => ({ text: sport, value: sport }));
    },
    regionsOptions() {
      return this.uniqueRegions.map(region => ({ text: region, value: region }));
    },
  },
  methods: {
    updateUniqueSports(sports) {
      this.uniqueSports = sports.slice();
    },
    updateUniqueRegions(regions) {
      this.uniqueRegions = regions.slice();
    },
    updateFilteredItems(items) {
      this.items = items.slice();
    },
    clearSearchTerm() {
      this.searchTerm = "";
    },
  },
};
</script>

<style lang="scss" scoped>
h2.leagues-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  font-family: "geom-graphic", sans-serif;
  font-weight: bold;
  color: #EBBA0A;
  padding-top: 10vh;
  padding-bottom: 2rem;
}

.filter-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: #BBBBBB;
  opacity: 1;
}

.filter-section {
  flex: 1;
  margin: 0.5rem;
  max-width: calc(100% / 2);
}

.custom-select {
  border: 2px solid #EBBA0A;
  background: #040505 0% 0% no-repeat padding-box;
  border-radius: 1.4rem;
  background-color: #040505;
  color: #BBBBBB;
  font-size: 1.3rem;
  width: 49%;
  margin-left: 0.2rem;
  opacity: 1;
  //b-form-select needs a custom caret icon
  background-image: url("./../../../assets/svgs/caret-leagues.svg");
  background-repeat: no-repeat;
  background-size: 16px 10px;
  background-position: right 10px center;
}
.styled-input {
  border: 2px solid #EBBA0A;
  background: #040505 0% 0% no-repeat padding-box;
  border-radius: 1.5rem;
  background-color: #040505;
  color: #BBBBBB;
  font-size: 1.3rem;
  width: 100%;
  box-sizing: border-box;
  padding-left: 2.5rem;
  opacity: 1;
}

.styled-input:focus {
  border-color: #EBBA0A;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: auto;
}

.input-icon {
  position: relative;
}

.input-icon::before {
  content: "";
  background-image: url("../../../assets/images/icons/search-icon/searchicon@2x.png");
  background-size: 1.1rem 1.1rem;
  background-repeat: no-repeat;
  background-position: left;
  width: 2rem;
  height: 95%;
  position: absolute;
  top: 0;
  left: 1rem;
  align-items: center;
  padding-left: 1rem;
  pointer-events: none;

}

/* Responsive styles for smaller screens */
@media (max-width: 992px) {
  h2.leagues-title {
    padding-top: 10vh;
    font-size: 4.5vw;
  }

  .filter-box {
    flex-direction: column;
    align-items: stretch;
    padding-top: 2vh;
    padding-bottom: 2vh;
  }

  .filter-section {
    max-width: 100%;
  }
  //filter-options is specifically for the second row, provides spacing between inputs
  .filter-options{
    display: flex;
    gap: 4px;
    .custom-select{
      font-size: 1.08rem;
      width: 100%;
    }
  }
}  
</style>
